import { ref } from '@vue/composition-api'
import store from '@/store'

export default function () {
  const generalData = ref([])
  const maleFilter = ref('')

  const maleOptions = [
    { label: '男', value: '1' },
    { label: '女', value: '2' },
  ]

  const getGeneralData = () => {
    store.dispatch('client-member/getGeneral')
      .then(response => {
        if (!response.data.success) return
        generalData.value = response.data.data
        if (generalData.value.male === 0)generalData.value.male = ''
        if (generalData.value.male === 1)generalData.value.male = { label: '男', value: '1' }
        if (generalData.value.male === 2)generalData.value.male = { label: '女', value: '2' }
        const userData = JSON.parse(localStorage.getItem('userData'))
        userData.role = {
          isAdmin: userData.role.isAdmin,
          name: response.data.data.role.name,
          power: response.data.data.role.power,
          router: userData.role.router,
        }
        localStorage.setItem('userData', JSON.stringify(userData))
        store.commit('app/UPDATE_USERDATA_STATE', userData)
      })
  }
  const setGeneralUpdate = (...args) => store.dispatch('client-member/setGeneralUpdate', ...args)
  const setGeneralImg = (...args) => store.dispatch('client-member/setGeneralImg', ...args)

  return {
    getGeneralData,
    setGeneralUpdate,
    setGeneralImg,
    generalData,

    maleFilter,
    maleOptions,
  }
}
