import { ref, computed } from '@vue/composition-api'
import store from '@/store'

export default function () {
  const workStateOptions = [
    { label: '工作階段', value: 1 },
    { label: '歷史紀錄', value: 0 },
  ]

  const perPage = ref(5)
  const perPageOptions = [5, 10, 20]
  const currentPage = ref(1)
  const workStateFilter = ref({ label: '工作階段', value: 1 })
  const searchQuery = ref('')
  const totalNum = ref(0)

  const workPhaseCard = ref([])

  const getWorkphase = () => store.dispatch('client-member/getWorkphase', {
    data: {
      limit: perPage.value,
      page: currentPage.value,
      search: searchQuery.value,
      filters: `state:${workStateFilter.value ? workStateFilter.value.value : null}`,
      _: Date.now(),
    },
  })
    .then(response => {
      if (!response.data.success) return
      workPhaseCard.value = response.data.data.data
      totalNum.value = response.data.data.total
    })

  const setWorkphaseDelete = (...args) => store.dispatch('client-member/setWorkphaseDelete', ...args)

  const dataMeta = computed(() => {
    const localItemsCount = workPhaseCard.value ? workPhaseCard.value.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalNum.value,
    }
  })

  return {
    workPhaseCard,
    getWorkphase,
    setWorkphaseDelete,

    perPage,
    currentPage,
    perPageOptions,
    workStateFilter,
    workStateOptions,
    searchQuery,
    dataMeta,
    totalNum,
  }
}
