<template>
  <div>
    <!-- 篩選 -->
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <!-- Entries -->
          <b-col
            cols="12"
            lg="2"
            class="align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>顯示</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="w-100 per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <b-col
            cols="12"
            lg="3"
            class="align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>篩選</label>
            <v-select
              v-model="workStateFilter"
              :options="workStateOptions"
              :clearable="false"
              class="w-100 per-page-selector d-inline-block mx-50"
            />
            <!-- <label>entries</label> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            lg="7"
          >
            <div class="align-items-center justify-content-end">
              <label>搜尋</label>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mx-50"
                placeholder="Search..."
              />
            </div>
          </b-col>

        </b-row>
      </div>
    </b-card>

    <!-- 工作階段 -->
    <div v-if="dataMeta.of !== 0">
      <b-card
        v-for="item in workPhaseCard"
        :key="item.index"
        no-body
        class="overflow-hidden"
      >
        <b-row no-gutters>
          <b-col
            sm="3"
            class="font-awesome-div"
            :style="{backgroundColor: `${isDark ? '#444b60' : '#e4e4e4'}`}"
          >
            <font-awesome-icon
              v-if="item.isPhone"
              icon="mobile-alt"
              class="font-awesome"
            />

            <font-awesome-icon
              v-else
              icon="desktop"
              class="font-awesome"
            />
          </b-col>

          <b-col class="content-div">
            <b-card-body :title="item.device_name">
              <b-container>
                <b-row>

                  <b-col lg="6">
                    <b-card-text class="item-name">
                      作業系統 : {{ item.os }}
                    </b-card-text>
                  </b-col>

                  <b-col lg="6">
                    <b-card-text class="item-name">
                      IP位置 : {{ item.ip }}
                    </b-card-text>
                  </b-col>

                  <b-col lg="6">
                    <b-card-text class="item-name">
                      位置 : {{ item.location }}
                    </b-card-text>
                  </b-col>

                  <b-col lg="6">
                    <b-card-text class="item-name">
                      裝置: {{ item.device }}
                    </b-card-text>
                  </b-col>

                  <b-col lg="6">
                    <b-card-text class="item-name">
                      瀏覽器 : {{ item.browser }}
                    </b-card-text>
                  </b-col>

                  <b-col lg="6">
                    <b-card-text class="item-name">
                      登入時間 : {{ moment(item.login_time).format("YYYY-MM-DD HH:mm:ss") }}
                    </b-card-text>
                  </b-col>

                </b-row>
              </b-container>
            </b-card-body>

            <hr>

            <div class="text-right m-1">
              <h5 v-if="item.isPresentToken">
                目前工作狀態
              </h5>
              <h5 v-else-if="!item.isPresentToken && !item.state">
                已登出
              </h5>
              <b-button
                v-else-if="!item.isPresentToken && item.state"
                size="sm"
                variant="primary"
                :disabled="!btnLock"
                @click="revokeWorkphase(item.id)"
              >
                撤銷
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <div v-else>
      <b-card no-body>
        <div class="text-center font-awesome-div">
          <font-awesome-icon
            icon="folder-open"
            size="5x"
            style="color: #e6e6e6"
          />
          <h6 class="text-muted">
            No Data
          </h6>
        </div>
      </b-card>
    </div>

    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalNum"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>

    </b-row>

  </div>
</template>

<script>
// API
import useAppConfig from '@core/app-config/useAppConfig'
import { watch, computed } from '@vue/composition-api'

// UI
import {
  BCard, BRow, BCol, BPagination, BFormInput, BCardText, BCardBody, BContainer, BButton,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'

// Components
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useWorkPhaseList from './setting'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BContainer,
    BRow,
    BCol,
    BCardText,
    BPagination,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      btnLock: true,
    }
  },
  methods: {
    moment,
    revokeWorkphase(id) {
      this.btnLock = false
      this.setWorkphaseDelete({
        id,
      })
        .then(response => {
          if (response.data.success) this.getWorkphase()
          this.btnLock = true
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: response.data.message,
              icon: response.data.success ? 'CheckIcon' : 'InfoIcon',
              variant: response.data.success ? 'success' : 'danger',
            },
          })
        })
    },
  },

  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      workPhaseCard,
      getWorkphase,
      setWorkphaseDelete,
      perPage,
      perPageOptions,
      currentPage,
      workStateFilter,
      workStateOptions,
      searchQuery,
      dataMeta,
      totalNum,
    } = useWorkPhaseList()

    getWorkphase()

    watch([workStateFilter, searchQuery, perPage, currentPage], () => {
      getWorkphase()
    }, {
      deep: true,
    })

    return {
      workPhaseCard,
      perPage,
      perPageOptions,
      currentPage,
      searchQuery,
      workStateFilter,
      workStateOptions,
      totalNum,
      dataMeta,
      isDark,
      getWorkphase,
      setWorkphaseDelete,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.font-awesome-div{
  margin: 20px;
  .font-awesome{
  // background-color: #e4e4e4;
  padding: 20px;
  height: 100%;
  width: 100%;
  max-height: 150px;
  // color: #f6f6f6;
  display:flex;
  align-items:center;
  justify-content:center;
}
}
.b-card-body {
  font-weight: bold;
}
.content-div{
  // width:80vw;
  .item-name{
  font-size: 1.2rem;
  margin: 5px;
}
}

</style>
