<template>
  <b-tabs
    v-model="tabIndex"
    vertical
    content-class="col-12 col-lg-10 col-md-9 mt-1 mt-md-0"
    pills
    lazy
    nav-wrapper-class="col-lg-2 col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- tab: 基本資料 -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">基本資料</span>
      </template>

      <member-general />
    </b-tab>

    <!-- tab: 通知紀錄 -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">通知紀錄</span>
      </template>

      <member-notify />
    </b-tab>

    <!-- tab: 工作階段 -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="AirplayIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">工作階段</span>
      </template>

      <member-workphase />
    </b-tab>

  </b-tabs>
</template>

<script>
// API
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
// UI
import { BTabs, BTab } from 'bootstrap-vue'
// Components
import MemberGeneral from '../../../components/client/member/general/General.vue'
import MemberNotify from '../../../components/client/member/notify/Notify.vue'
import MemberWorkphase from '../../../components/client/member/workphase/Workphase.vue'
import memberStoreModule from '../../../components/client/member/useStoreModule'

export default {
  components: {
    BTabs,
    BTab,

    MemberGeneral,
    MemberNotify,
    MemberWorkphase,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  setup() {
    // 模組註冊
    const MEMBER_CLIENT_STORE_MODULE_NAME = 'client-member'

    if (!store.hasModule(MEMBER_CLIENT_STORE_MODULE_NAME)) store.registerModule(MEMBER_CLIENT_STORE_MODULE_NAME, memberStoreModule)

    onUnmounted(() => {
      if (store.hasModule(MEMBER_CLIENT_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_CLIENT_STORE_MODULE_NAME)
    })
  },
}
</script>
