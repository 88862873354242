import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // ---------------------------------------------個人資料----------------------------------------------------------
    // (查詢)會員資料
    getGeneral() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/user', { headers: { token: useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (變更)會員頭像
    setGeneralImg(ctx, data) {
      return new Promise((resolve, reject) => {
        axios('/api/user/upload', {
          method: 'POST',
          headers: {
            token: useJwt.getToken(),
          },
          data: {
            ...data,
          },
        })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    // (編輯)會員資料
    setGeneralUpdate(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios('/api/user/update', {
          method: 'POST',
          headers: {
            token: useJwt.getToken(),
          },
          data: {
            ...userData,
          },
        })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    // ---------------------------------------------工作階段----------------------------------------------------------
    // (查詢)工作階段
    getWorkphase(ctx, workphase) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/user/login', { headers: { token: useJwt.getToken() }, params: workphase.data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (撤銷)工作階段
    setWorkphaseDelete(ctx, workphase) {
      return new Promise((resolve, reject) => {
        axios('/api/user/login/revoke', {
          method: 'POST',
          headers: {
            token: useJwt.getToken(),
          },
          data: {
            ...workphase,
          },
        })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
