<template>
  <b-card v-if="generalData">
    <!-- 媒體 -->
    <b-media no-body>

      <!-- 大頭貼 -->
      <b-media-aside @click="$refs.refInputEl.$el.click()">
        <b-link>
          <b-avatar
            ref="previewEl"
            rounded
            size="12rem"
            :src="generalData.image"
          />
        </b-link>
      </b-media-aside>

      <!-- 上傳/重設 -->
      <b-media-body class="mt-75 ml-75">
        <div
          v-if="userDataRole.power !== 0"
          class="mb-1"
        >
          <h2>{{ generalData.name }}</h2>
        </div>

        <!-- 上傳按鈕 -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          上傳
        </b-button>

        <!-- 重設按鈕 -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="removeImage"
        >
          重設
        </b-button>

        <!-- 說明 -->
        <b-card-text>
          只允許副檔名為JPG, PNG的檔案.
        </b-card-text>

        <!-- 信箱驗證警告 -->
        <b-alert
          :show="userDataRole.power === 0"
          variant="warning"
          class="mb-50"
        >
          <h4 class="alert-heading">
            您的電子郵件尚未驗證，請檢查您的收件箱。
          </h4>
          <div class="alert-body">
            <b-link
              class="alert-link"
              @click="resendEmail"
            >
              沒有收到認證信嗎?點我重發
            </b-link>
          </div>
        </b-alert>

        <!-- 上傳圖片 -->
        <b-form-file
          ref="refInputEl"
          accept=".jpg, .png"
          :hidden="true"
          plain
          @change="loadImage($event)"
        />

        <!-- 圖片裁剪 -->
        <b-modal
          id="modal"
          size="lg"
          centered
          :no-close-on-backdrop="close"
          :no-close-on-esc="close"
        >

          <template #modal-header>
            <h4>圖片裁剪</h4>
          </template>

          <div class="upload-example">
            <cropper
              ref="cropper"
              :src="image"
              :stencil-props="{
                aspectRatio: 1/1,
              }"
              :canvas="{
                minHeight: 0,
                minWidth: 0,
                maxHeight: 300,
                maxWidth: 300,
              }"
              :auto-zoom="false"
              :debounce="false"
              @change="onChange"
            />

            <preview
              :width="200"
              :height="200"
              :image="result.image"
              :coordinates="result.coordinates"
            />
          </div>

          <template #modal-footer>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="cancel"
            >
              取消
            </b-button>

            <b-button
              size="sm"
              variant="primary"
              @click="uploadImage"
            >
              上傳
            </b-button>
          </template>
        </b-modal>

      </b-media-body>
    </b-media>

    <!-- 表單 -->
    <validation-observer
      ref="memberGeneralForm"
      #default="{invalid}"
    >
      <b-form class="mt-2">
        <b-row>

          <!-- 使用者姓名 -->
          <b-col lg="4">
            <b-form-group label="使用者姓名">
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model="generalData.name"
                    placeholder="使用者姓名"
                    :state="errors.length > 0 ? false:null"
                    name="username"
                  />
                  <b-input-group-append is-text />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- 使用者中文姓名 -->
          <b-col
            lg="4"
            md="6"
          >
            <b-form-group label="中文姓名">
              <b-input-group>
                <b-form-input
                  v-model="generalData.ch_name"
                  name="username"
                  placeholder="使用者中文姓名"
                  class="form-control"
                />
              </b-input-group>

            </b-form-group>
          </b-col>

          <!-- 使用者英文姓名 -->
          <b-col
            lg="4"
            md="6"
          >
            <b-form-group label="英文姓名">
              <b-input-group>
                <b-form-input
                  v-model="generalData.en_name"
                  name="username"
                  placeholder="使用者英文姓名"
                  class="form-control"
                />
              </b-input-group>

            </b-form-group>
          </b-col>

          <!-- 電子信箱 -->
          <b-col lg="4">
            <b-form-group label="電子信箱">
              <b-input-group>
                <b-form-input
                  v-model="generalData.email"
                  name="email"
                  placeholder="Email"
                  disabled
                  class="form-control"
                />
              </b-input-group>

            </b-form-group>
          </b-col>

          <!-- 連絡電話 -->
          <b-col lg="3">
            <b-form-group
              label="連絡電話"
            >
              <b-form-input
                v-model="generalData.phone"
                name="phone"
                placeholder="電話號碼"
              />

            </b-form-group>
          </b-col>

          <!-- 性別 -->
          <b-col
            lg="2"
            md="6"
          >
            <b-form-group
              label="性別"
            >
              <v-select
                v-model="generalData.male"
                :options="maleOptions"
                placeholder="請選擇性別"
              />
            </b-form-group>
          </b-col>

          <!-- 生日 -->
          <b-col
            lg="3"
            md="6"
          >
            <b-form-group
              label="生日"
            >
              <b-input-group>
                <cleave
                  id="date"
                  v-model="generalData.birthday"
                  class="form-control"
                  :raw="false"
                  :options="options.date"
                  placeholder="YYYY-MM-DD"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="generalData.birthday"
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    locale="zh-TW"
                    aria-controls="example-input"
                    :date-disabled-fn="dateDisabled"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- 地址 -->
          <b-col sm="12">
            <b-form-group
              label="地址"
            >
              <b-form-input
                v-model="generalData.address"
                name="address"
                placeholder="地址"
              />
            </b-form-group>
          </b-col>

          <!-- 更改/重設 -->
          <b-col
            cols="12"
            class="text-right"
          >

            <!-- 更改按鈕 -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2"
              :disabled="invalid"
              @click="setGeneralData"
            >
              儲存
            </b-button>

          </b-col>

        </b-row>

      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
// API
import router from '@/router'
import store from '@/store'
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'

// UI
import {
  BModal, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside,
  BMediaBody, BLink, BAvatar, BFormDatepicker, BInputGroup, BInputGroupAppend, BAlert,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'

// Components
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import useGeneral from './setting'

export default {
  components: {
    BButton,
    BForm,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BAlert,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BFormFile,

    Cropper,
    Preview,
    Cleave,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      userDataRole: {
        power: null,
        name: null,
      },
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
      },

      required,
      close: true,
      image: null,
      result: {
        coordinates: null,
        image: null,
      },
    }
  },
  updated() {
    this.userDataRole = this.generalData.role
  },
  methods: {
    // 鎖定日期
    dateDisabled(ymd, date) {
      return date > moment().endOf('day')
    },

    // 圖片預覽
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      }
    },

    // 圖片裁剪
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      this.image = canvas.toDataURL()
    },

    // 載入圖片
    loadImage(event) {
      const dirNameSplit = event.target.files[0].name.split('.')
      const type = dirNameSplit[dirNameSplit.length - 1]
      if (type !== 'jpg' && type !== 'png' && type !== 'PNG' && type !== 'JPG') return
      this.$bvModal.show('modal')
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => { this.image = e.target.result }
        reader.readAsDataURL(input.files[0])
      }
    },

    // (上傳)圖片
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const formData = new FormData()
        canvas.toBlob(blob => {
          formData.append('file', blob)
          this.setGeneralImg({
            data: canvas.toDataURL(),
          })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: response.data.message,
                  icon: response.data.success ? 'success' : 'InfoIcon',
                  variant: response.data.success ? 'success' : 'danger',
                },
              })
              if (!response.data.success) return
              const userData = JSON.parse(localStorage.getItem('userData'))
              userData.image = response.data.data
              localStorage.setItem('userData', JSON.stringify(userData))
              store.commit('app/UPDATE_USERDATA_STATE', userData)
              this.generalData.image = response.data.data
            })
        })
      }
      this.$refs.refInputEl.reset()
      this.image = null
      this.$bvModal.hide('modal')
      this.result.coordinates = null
    },

    // (刪除)圖片
    removeImage() {
      this.setGeneralUpdate({
        id: this.generalData.id,
        image: null,
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.message,
              icon: response.data.success ? 'success' : 'InfoIcon',
              variant: response.data.success ? 'success' : 'danger',
            },
          })
          if (!response.data.success) return
          const userData = JSON.parse(localStorage.getItem('userData'))
          userData.image = null
          localStorage.setItem('userData', JSON.stringify(userData))
          store.commit('app/UPDATE_USERDATA_STATE', userData)
          this.generalData.image = null
        })
    },

    // 取消上傳
    cancel() {
      this.$bvModal.hide('modal')
      this.$refs.refInputEl.reset()
      this.result.coordinates = null
      this.image = null
    },

    // (寄送)驗證信
    resendEmail() {
      store.dispatch('client/resendEmail')
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.message,
              icon: response.data.success ? 'success' : 'InfoIcon',
              variant: response.data.success ? 'success' : 'danger',
            },
          })
        })
    },

  },
  setup() {
    const Alert = router.currentRoute.params.alert
    const toast = useToast()

    localize('tw')

    const {
      generalData,
      getGeneralData,
      setGeneralImg,
      setGeneralUpdate,
      maleFilter,
      maleOptions,
    } = useGeneral()

    getGeneralData()

    const setGeneralData = () => {
      setGeneralUpdate({
        id: generalData.value.id,
        ch_name: generalData.value.ch_name,
        en_name: generalData.value.en_name,
        phone: generalData.value.phone,
        address: generalData.value.address,
        birthday: generalData.value.birthday,
        male: generalData.value.male ? generalData.value.male.value : 0,
        name: generalData.value.name,
      })
        .then(response => {
          if (response.data.success) {
            const userData = JSON.parse(localStorage.getItem('userData'))
            userData.name = generalData.value.name
            localStorage.setItem('userData', JSON.stringify(userData))
            store.commit('app/UPDATE_USERDATA_STATE', userData)
            getGeneralData()
          }
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.message,
              icon: response.data.success ? 'success' : 'InfoIcon',
              variant: response.data.success ? 'success' : 'danger',
            },
          })
        })
    }

    if (Alert) {
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: Alert,
          icon: 'InfoIcon',
          variant: 'danger',
        },
      })
    }

    return {
      generalData,
      getGeneralData,
      setGeneralData,
      setGeneralImg,
      setGeneralUpdate,
      maleFilter,
      maleOptions,
      Alert,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
